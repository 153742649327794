<template>
    <div>
        <sidebar />
        <div class="flex" id="content">
            <div class="w-1/5" v-if="sidebar.Menu"></div>
            <div :class="sidebar.Menu ? 'w-4/5' : 'w-full'" class="p-5">
                <router-view></router-view>
            </div>
        </div>
        <!-- <router-view></router-view> -->
    </div>
</template>
<script>
export default {
    computed: {
        sidebar () {
            return this.$sidebar.value
        }
    },
    components: {
        Sidebar: () => import('./components/sidebar')
    },
    mounted () {
        this.getCompany()
    }
}
</script>
